import React from "react";
import "../../../tailwind.generated.css";

import TextDropdown from "../../TextDropdown";
import SmartInput from "../../SmartInput";

import { getFigmaContainsFilterOptions, getFigmaCopmletedOnFilterOptions, getScaleRepliesList } from "../utils";

import { ReactComponent as IconDelete } from "../../../icons/trash.svg";
import { ReactComponent as IconTag } from "../../../icons/tag.svg";

import { IReportTestData, IReportFilter, IBlockFilterCondition, ISourceFilterCondition, IUrlFilterCondition, PanalAnswerStatuses, IPanelStatusFilterCondition, FilterType } from "../../../models/Report";

import { sourceFilterValues, urlParameterConditions } from "./constants";
import { IconHire, IconFilter } from "../../../icons";
import { blockTypeMap } from "../../Common";
import { getBlockName } from "../../TestEditor/utils";
import { alphabet } from "../../TestEditor/constants";
import lodash from "lodash";
import { Trans, useTranslation } from "react-i18next";
import { BlockType, isFigmaBlock } from '../../../models/Test';
import { usePrototypeQuery } from '../../UserAccount/Hooks';
import { getBlockFilterConditions } from "./utils";


const SourceCondition = ({
  data,
  condition,
  handleUpdateCondition,
}: {
  data: IReportTestData;
  condition: ISourceFilterCondition;
  handleUpdateCondition: (key: string, value: string) => void;
}) => {
  const { t } = useTranslation();

  const translatedFilterItems = sourceFilterValues.map(item => ({
    ...item,
    name: <Trans>{item.name}</Trans>,
  }));

  const haveCustomPanelReplies = Object.keys(data.answersMetaData).some((answerId) => {
    return data.answersMetaData[answerId].source === "customPanel";
  });

  const translatedFilterItemsWithoutCustomPanel = translatedFilterItems.filter((item) => item.value !== "customPanel");


  return (
    <div className="flex items-center my-2">
      <IconHire width={20} height={20} className="fill-current text-gray-800 mr-2" />
      <div className="mr-2 whitespace-nowrap overflow-hidden text-ellipsis">{t("Show respondents from")}</div>
      <div className="mr-2">
        <TextDropdown
          className="inline-block"
          small
          items={haveCustomPanelReplies ? translatedFilterItems : translatedFilterItemsWithoutCustomPanel}
          value={condition.value}
          placeholder={t("Pick an option")}
          onChange={(value) => {
            handleUpdateCondition("value", value);
          }}
        />
      </div>
    </div>
  );
};

const UrlParameterCondition = ({
  data,
  condition,
  handleUpdateCondition,
}: {
  data: IReportTestData;
  condition: IUrlFilterCondition;
  handleUpdateCondition: (key: string, value: string) => void;
}) => {
  const { t } = useTranslation();
  const urlTagsList: Array<string> = [];
  Object.keys(data.answersMetaData).forEach((answerId) => {
    const urlTags = data.answersMetaData[answerId].urlTags;
    if (urlTags !== undefined) {
      Object.keys(urlTags).forEach((tag) => {
        if (!urlTagsList.includes(tag)) {
          urlTagsList.push(tag);
        }
      });
    }
  });

  const getValueField = () => {
    if (condition.condition === "inAnswer") {
      return null;
    } else if (condition.condition === "contains" || condition.condition === "is") {
      return (
        <SmartInput
          className="default-input-small w-[120px]"
          initialValue={""}
          placeholder={t("Enter value")}
          id={condition.id}
          onChange={(value: string) => {
            handleUpdateCondition("value", value);
          }}
          onBlur={undefined}
          focusOnMount={undefined}
          large={undefined}
          extralarge={undefined}
          small
        />
      );
    } else {
      return null;
    }
  };

  const valueField = getValueField();

  return (
    <div className="h-10 flex items-center">
      <IconTag width={20} height={20} className="fill-current text-gray-800 mr-2" />
      <TextDropdown
        parentTestId="pick-a-tag"
        className="inline-block mr-2"
        small
        items={urlTagsList.map((tag) => {
          return {
            name: tag,
            value: tag,
          };
        })}
        value={condition.tag}
        placeholder={t("Pick a tag")}
        onChange={(value) => {
          handleUpdateCondition("tag", value);
        }}
      />
      {urlTagsList.length > 0 && (
        <TextDropdown
          parentTestId="pick-a-condition"
          className="inline-block mr-2"
          small
          items={urlParameterConditions}
          value={condition.condition as string}
          placeholder={t("Pick a condition")}
          onChange={(value) => {
            handleUpdateCondition("condition", value);
          }}
        />
      )}
      {valueField && <div className="mr-2">{valueField}</div>}
    </div>
  );
};

const BlockCondition = ({
  data,
  condition,
  handleUpdateCondition,
}: {
  data: IReportTestData;
  condition: IBlockFilterCondition;
  handleUpdateCondition: (key: string, value: string) => void;
}) => {
  const { t } = useTranslation();
  const block = data.publishedContent.find((block) => block.blockId === condition.blockId);
  const blockIndex = data.publishedContent.findIndex((block) => block.blockId === condition.blockId);

  const blockType = block?.type;
  let prototype = usePrototypeQuery(block && isFigmaBlock(block) ? block.prototypeId : undefined);

  if (!block || !blockType) {
    return null;
  }

  const blockTitle = blockTypeMap[blockType];
  const question = {
    name: block.name || blockTitle?.name,
    icon: blockTitle?.getIcon({ width: "20px", height: "20px" }),
    i: blockIndex,
  };
  const blockConditions = getBlockFilterConditions(blockType, block);

  const getValueField = () => {
    if (blockType === BlockType.openquestion) {
      if (condition.condition === "contains" || condition.condition === "doesNotContain") {
        return (
          <SmartInput
            className="default-input-small w-[160px] grow-default"
            initialValue={""}
            placeholder={t("Enter value")}
            id={condition.id}
            onChange={(value: any) => {
              handleUpdateCondition("value", value);
            }}
            onBlur={undefined}
            focusOnMount={undefined}
            large={undefined}
            extralarge={undefined}
            small={true}
          />
        );
      } else {
        return null;
      }
    }
    if (blockType === BlockType.choice) {
      if (condition.condition === "contains" || condition.condition === "doesNotContain") {
        //@ts-ignore
        const dropdownOptions = block.replies.map((reply) => {
          return { name: reply.replyValue, value: reply.id };
        });
        //@ts-ignore
        if (block.other) {
          dropdownOptions.push({ name: t("Other"), value: "other" });
        }
        return (
          <TextDropdown
            className="shrink-0 w-full"
            small
            items={dropdownOptions}
            value={condition.value}
            placeholder={t("Choose a reply value")}
            onChange={(value) => {
              handleUpdateCondition("value", value);
            }}
          />
        );
      } else {
        return null;
      }
    }
    if (blockType === BlockType.preference) {
      if (condition.condition === "contains" || condition.condition === "doesNotContain") {
        const dropdownOptions = block.replies
          .filter((reply) => reply.image !== ("" || undefined || null))
          .map((reply, i) => ({
            name: (
              <div className="flex items-center">
              <div className="rounded-[6px] bg-gray-400 text-gray-700 font-bold text-xs w-5 h-5 mr-2 shrink-0">
                <div className="capitalize flex items-center justify-center w-full h-full">
                {alphabet[i]}
                </div>
                </div>
              <img src={reply.image} alt={reply.replyValue} className="h-5 w-5 rounded-sm block object-cover" />
            </div>
            ),
            value: reply.id,
          }));
                return (
          <TextDropdown
            className="inline-block"
            small
            items={dropdownOptions}
            value={condition.value}
            placeholder={t("Choose a reply value")}
            onChange={(value) => {
              handleUpdateCondition("value", value);
            }}
          />
        );
      } else {
        return null;
      }
    }
    if (blockType === BlockType.scale) {
      if (condition.condition === "is") {
        const dropdownOptions = getScaleRepliesList(block);
        return (
          <TextDropdown
            className="inline-block"
            small
            items={dropdownOptions}
            value={condition.value}
            placeholder={t("Choose a reply value")}
            onChange={(value) => {
              handleUpdateCondition("value", value);
            }}
          />
        );
      } else {
        return null;
      }
    }
    if (blockType === BlockType.figma) {
      if (condition.condition === "completedOn") {
        const dropdownOptions = getFigmaCopmletedOnFilterOptions(block, prototype.data);
        return <TextDropdown
          className="inline-block"
          small
          items={dropdownOptions}
          value={condition.value}
          placeholder={t("Choose a screen")}
          onChange={(value) => {
            handleUpdateCondition("value", value);
          }}
        />
      }
      if (condition.condition === "contains") {
        const dropdownOptions = getFigmaContainsFilterOptions(block, prototype.data);
        return <TextDropdown
          className="inline-block"
          small
          items={dropdownOptions}
          value={condition.value}
          placeholder={t("Choose a screen")}
          onChange={(value) => {
            handleUpdateCondition("value", value);
          }}
        />
      }
      if (condition.condition === "notCompleted") {
        return null;
      }
    }
    return null;
  };



  return (
    <div className="flex items-center h-10">
      <div className="mr-2">
        <div className="flex items-center justify-start">
          <span className="mr-2">{question.icon}</span>
          <span className="block max-w-[140px] text-ellipsis overflow-hidden whitespace-nowrap text-gray-800"><Trans>{getBlockName(block)}</Trans></span>
        </div>
      </div>
      {condition.blockId && (
        <div className="mr-2 shrink-0">
          <TextDropdown
            className="inline-block"
            small
            items={blockConditions.map((condition) => {
              return { name: <Trans>{condition.name}</Trans>, value: condition.value };
            })}
            value={condition.condition as string}
            placeholder={t("Pick a condition")}
            onChange={(value) => {
              handleUpdateCondition("condition", value);
            }}
          />
        </div>
      )}
      {condition.condition && condition.condition !== "hasAnswer" && <div className="flex justify-center mr-2 shrink-0 max-w-[160px]">{getValueField()}</div>}
    </div>
  );
};

const PanelStatusCondition = ({
  data,
  condition,
  handleUpdateCondition,
}: {
  data: IReportTestData;
  condition: IPanelStatusFilterCondition;
  handleUpdateCondition: (key: string, value: string) => void;
}) => {
  const { t } = useTranslation();
  const avaliableStatuses = lodash(data.answersMetaData)
    .values()
    .filter("panelRespondentStatus")
    .groupBy("panelRespondentStatus")
    .mapValues((value, key) => ({ name: key, value: key }))
    .assignIn({
      [PanalAnswerStatuses.completed]: {
        name: PanalAnswerStatuses.completed,
        value: PanalAnswerStatuses.completed
      }
    })
    .values()
    .value()

  return (
    <div className="flex items-center my-2">
      <IconFilter
        width={24}
        height={24}
        className="fill-current text-gray-800 mr-2"
      />
      <div className="mr-2 font-medium">{t("Show panel respondents whose status is marked as")}</div>
      <div className="mr-2">
        <TextDropdown
          className="inline-block"
          small
          items={avaliableStatuses}
          value={condition.value}
          placeholder={t("Pick an option")}
          onChange={(value) => {
            handleUpdateCondition("value", value);
          }}
        />
      </div>
    </div>
  );
};

const ReportFilterCondition = ({
  i,
  filter,
  id,
  data,
  handleUpdateCondition,
  handleDeleteCondition,
}: {
  i: number;
  filter: IReportFilter;
  id: string;
  data: IReportTestData;
  handleUpdateCondition: (key: string, value: string) => void;
  handleDeleteCondition: () => void;
}) => {
  const { t } = useTranslation();
  const condition = filter.conditions[id];
  const type = condition.type;

  return (
    <div className="filterCondition">
      {i !== 0 && (
        <div className="flex items-center my-2">
          <TextDropdown
            small
            items={[
              { name: t("and"), value: "and" },
              { name: t("or"), value: "or" },
            ]}
            value={condition.operator as string}
            onChange={(value) => {
              handleUpdateCondition("operator", value);
            }}
            position={undefined}
            className={undefined}
            placeholder={undefined}
          />
        </div>
      )}
      <div className="flex items-center">
        {type === "block" && (
          <BlockCondition condition={condition as IBlockFilterCondition} data={data} handleUpdateCondition={handleUpdateCondition} />
        )}
        {type === "source" && (
          <SourceCondition condition={condition as ISourceFilterCondition} data={data} handleUpdateCondition={handleUpdateCondition} />
        )}
        {type === "urlParameter" && (
          <UrlParameterCondition condition={condition as IUrlFilterCondition} data={data} handleUpdateCondition={handleUpdateCondition} />
        )}
        {type === FilterType.panelRespondentStatus && (
          <PanelStatusCondition condition={condition as IPanelStatusFilterCondition} data={data} handleUpdateCondition={handleUpdateCondition} />
        )}
        <div className="shrink-0">
          <IconDelete
            className="fill-current text-gray-700 hover:text-red-600 cursor-pointer"
            width={20}
            height={20}
            onClick={() => {
              handleDeleteCondition();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ReportFilterCondition;
